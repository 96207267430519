import React from 'react';
import { createContext, useContext } from 'react';
import { OpenfitMetadataQuery } from 'graphql-types';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface MetadataProperties {}

export type Metadata =
  | MetadataProperties
  | Record<keyof MetadataProperties | string, string | boolean>;

export const MetadataContext = createContext<MetadataProperties>(
  {} as MetadataProperties
);

export const useMetadata = () => useContext(MetadataContext);

export const MetadataProvider: React.FC<{
  value?: OpenfitMetadataQuery;
}> = ({ children, value }) => {
  const metadata =
    value?.allSanityMetadata?.nodes?.[0]?.metadata?.reduce(
      (acc: Metadata, red: { key: string; value: string }) => {
        return { ...acc, [red.key]: JSON.parse(red.value) };
      },
      {}
    ) || {};

  return (
    <MetadataContext.Provider value={{ ...metadata } as MetadataProperties}>
      {children}
    </MetadataContext.Provider>
  );
};
