import styled, { css } from 'styled-components';
import {
  height,
  lineHeight,
  LineHeightProps,
  fontSize,
  FontSizeProps,
  minWidth,
  variant,
  width,
  WidthProps,
  typography,
  layout,
  border,
} from 'styled-system';
import {
  ButtonProps,
  TinyButtonProps,
  Button2Props,
  Button2Variant,
} from './model';

export const StyledButton = styled.a<
  ButtonProps & WidthProps & FontSizeProps & LineHeightProps
>`
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  appearance: none;
  font-weight: ${({ theme }) => theme.fontWeights.black};
  color: ${({ theme }) => theme.colors.basic100};
  display: table;
  text-align: center;
  text-decoration: none;
  outline: none;
  border-radius: 35px;
  border: 1px solid currentColor;
  ${minWidth({ minWidth: ['193px', '242px'] })};
  ${fontSize({ fontSize: ['16px', '18px'] })};
  ${height({ height: ['46px', '54px'] })};
  ${lineHeight({ lineHeight: ['46px', '54px'] })};
  ${(props) =>
    props.fullWidthMobile &&
    css`
      ${minWidth({ minWidth: ['100%', '242px'] })}
    `};
  ${width};
  ${fontSize};
  ${lineHeight};

  box-shadow: ${(props) =>
    props.dropShadow && '0px 1px 4px rgba(0, 0, 0, 0.25)'};
  cursor: ${(props) => props.showPointer && 'pointer'};

  ${({ theme }) =>
    variant({
      variants: {
        primary: {
          backgroundColor: theme.colors.red400,
          borderColor: theme.colors.red400,
          '&:hover': {
            backgroundColor: theme.colors.orangeHover,
          },
        },
        secondary: {
          backgroundColor: theme.colors.blue400,
          borderColor: theme.colors.blue400,
          '&:hover': {
            backgroundColor: theme.colors.blueHover,
          },
        },
        secondaryInverted: {
          backgroundColor: theme.colors.basic100,
          borderColor: theme.colors.basic400,
          color: theme.colors.blue400,
          '&:hover': {
            color: theme.colors.blueHover,
          },
        },
        secondaryInvertedTransparent: {
          backgroundColor: 'transparent',
          borderColor: theme.colors.basic400,
          color: theme.colors.blue400,
          '&:hover': {
            color: theme.colors.blueHover,
          },
        },
        transparent: {
          backgroundColor: 'transparent',
          borderColor: theme.colors.basic400,
          color: theme.colors.basic800,
          '&:hover': {
            backgroundColor: theme.colors.blue800,
            color: theme.colors.basic100,
          },
        },
        transparentBorderBasic200: {
          backgroundColor: 'transparent',
          borderColor: theme.colors.basic200,
          color: theme.colors.basic200,
          '&:hover': {
            backgroundColor: theme.colors.basic100,
            color: theme.colors.blue400,
            borderColor: theme.colors.basic200,
          },
        },
        disabled: {
          backgroundColor: theme.colors.basic400,
          borderColor: theme.colors.basic400,
          color: theme.colors.basic100,
          pointerEvents: 'none',
        },
        primaryBorder: {
          backgroundColor: 'transparent',
          color: theme.colors.red400,
          '&:hover': {
            color: theme.colors.basic100,
            background: theme.colors.red400,
            borderColor: theme.colors.red400,
          },
        },
      },
    })};
`;

export const StyledTinyButton = styled.a<TinyButtonProps>`
  ${fontSize({ fontSize: ['12px'] })};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  text-transform: uppercase;
  line-height: 24px;
  height: 24px;
  outline: none;
  border-radius: 18px;
  border: 1px solid currentColor;
  max-width: 143px;
  text-decoration: none;

  ${({ theme }) =>
    variant({
      variants: {
        primary: {
          backgroundColor: theme.colors.red400,
          color: theme.colors.basic100,
          '&:hover': {
            backgroundColor: theme.colors.basic100,
            color: theme.colors.red400,
          },
        },
        secondary: {
          backgroundColor: theme.colors.blue400,
          color: theme.colors.basic100,
          '&:hover': {
            opacity: 0.7,
          },
        },
        disabled: {
          color: theme.colors.basic400,
          cursor: 'default',
        },
        primaryBorder: {
          backgroundColor: 'transparent',
          color: theme.colors.red400,
          '&:hover': {
            color: theme.colors.basic100,
            background: theme.colors.red400,
            borderColor: theme.colors.red400,
          },
        },
        primaryUnderline: {
          color: theme.colors.red400,
          textDecoration: 'underline',
          outline: 'none',
          border: 'none',
          '&:hover': {
            opacity: 0.7,
          },
        },
      },
    })};
`;

export const StyledButton2 = styled.a<Button2Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) =>
    typography({
      fontSize: [12, 14],
      lineHeight: ['16px', '20px'],
      fontWeight: [theme.fontWeights.regular, theme.fontWeights.black],
    })};
  ${layout({ height: [32, 42], width: [84, 150] })};
  ${border({ borderRadius: [16, 21] })};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  outline: none;
  border: 1px solid currentColor;
  text-decoration: none;
  cursor: pointer;
  ${({ theme }) =>
    variant({
      variants: {
        [Button2Variant.MarketingRed]: {
          backgroundColor: theme.colors.red400,
          borderColor: theme.colors.red400,
          color: theme.colors.basic100,
          '&:hover': {
            backgroundColor: theme.colors.orangeHover,
            borderColor: theme.colors.orangeHover,
          },
        },
        [Button2Variant.PrimaryBlue]: {
          backgroundColor: theme.colors.blue400,
          borderColor: theme.colors.blue400,
          color: theme.colors.basic100,
          '&:hover': {
            backgroundColor: theme.colors.blueHover,
            borderColor: theme.colors.blueHover,
          },
        },
        [Button2Variant.PrimaryRed]: {
          backgroundColor: theme.colors.red800,
          borderColor: theme.colors.red800,
          color: theme.colors.basic100,
          '&:hover': {
            backgroundColor: theme.colors.redHover,
            borderColor: theme.colors.redHover,
          },
        },
        [Button2Variant.SecondaryBlue]: {
          backgroundColor: theme.colors.transparent,
          borderColor: theme.colors.basic400,
          color: theme.colors.blue400,
          '&:hover': {
            color: theme.colors.blueHover,
          },
        },
        [Button2Variant.SecondaryRed]: {
          backgroundColor: theme.colors.transparent,
          borderColor: theme.colors.red800,
          color: theme.colors.red800,
          '&:hover': {
            color: theme.colors.redHover,
            borderColor: theme.colors.redHover,
          },
        },
      },
    })};
`;

StyledButton.defaultProps = {
  variant: 'primary',
  dropShadow: false,
  showPointer: true,
  fullWidthMobile: false,
};

StyledTinyButton.defaultProps = {
  variant: 'primary',
};

StyledButton2.defaultProps = {
  variant: Button2Variant.MarketingRed,
};
