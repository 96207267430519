import * as React from 'react';
import { createGlobalStyle } from 'styled-components';
import { Reset } from 'styled-reset';

const GlobalStyles = createGlobalStyle`
  html, body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
    @media screen and (prefers-reduced-motion: reduce) {
      scroll-behavior: auto;
    }
    font-family: ${({ theme }) => theme.fonts.default};
  }



  body {
    color: ${({ theme }) => theme.colors.default};
  }

  *, :after, :before {
    box-sizing: border-box;
    outline: none;
  }
`;

const Styles = (): React.ReactElement => (
  <>
    <Reset />
    <GlobalStyles />
  </>
);

export default Styles;
