import React from 'react';

export const useIsBrowser = () => {
  const [isBrowser, setIsBrowser] = React.useState(false);
  React.useLayoutEffect((): void => {
    setIsBrowser(true);
  }, []);

  return isBrowser;
};

export const useTimeout = (callback: () => void, timeout = 0): (() => void) => {
  const timeoutIdRef = React.useRef<NodeJS.Timeout>();
  const cancel = React.useCallback(() => {
    const timeoutId = timeoutIdRef.current;
    if (timeoutId) {
      timeoutIdRef.current = undefined;
      clearTimeout(timeoutId);
    }
  }, [timeoutIdRef]);

  React.useEffect(() => {
    timeoutIdRef.current = setTimeout(callback, timeout);
    return cancel;
  }, [callback, timeout, cancel]);

  return cancel;
};
