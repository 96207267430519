import { Button } from 'components/Common/Button';
import { device } from 'theme';
import styled, { css } from 'styled-components';
import { space } from 'styled-utils';

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  @media (min-width: ${device.medium.width}) {
    padding-top: 10px;
  }
`;

export const ErrorImage = styled.img`
  display: flex;
  align-self: center;
  margin: auto;
  max-width: 100%;
  height: auto;
  vertical-align: top;
`;

export const ErrorMessage = styled.div`
  max-width: 500px;
  font-size: ${({ theme }) => theme.fontSizes.large};
  line-height: 24px;
  text-align: center;
  @media (min-width: ${device.medium.width}) {
    font-size: 22px;
  }
`;

export const ErrorTitle = styled.h1`
  font-size: 20px;
  font-weight: ${({ theme }) => theme.fontWeights.black};
  line-height: 1.35;
  text-align: center;
  @media (min-width: ${device.medium.width}) {
    font-size: 32px;
  }
  padding-bottom: 10px;
`;

export const ErrorImageContainer = styled.div`
  width: 200px;
  @media (min-width: ${device.medium.width}) {
    width: 293px;
  }
`;

export const ErrorButton = styled(Button).attrs({
  variant: 'secondary',
})`
  font-size: 16px;
  width: 100%;
`;

export const SecondaryErrorButton = styled(ErrorButton).attrs({
  variant: 'secondaryInverted',
})`
  background-color: white;
  color: ${({ theme }) => theme.colors.blue400};
  border: 1px solid ${({ theme }) => theme.colors.blue400};
  margin-top: 28px;
  @media (min-width: ${device.medium.width}) {
    margin-top: 38px;
  }
`;

export const SmallImageContainer = styled.div`
  width: 100px;
  @media (min-width: ${device.medium.width}) {
    width: 130px;
  }
`;

export const StyledContainer = styled.div<{ fullWidth: boolean }>`
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.basic100};

  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 'calc(100% - 40px)';

  @media (min-width: ${device.medium.width}) {
    width: ${device.medium.width};
  }

  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `};
`;

export const StyledInnerWrap = styled.section`
  ${space({ px: ['20px'], pt: ['30px'] })};
  margin-left: auto;
  margin-right: auto;
  max-width: 360px;

  @media (min-width: ${device.small.width}) {
    ${space({ px: ['20px'], pt: ['50px'] })};
  }

  h1 {
    font-size: 26px;
    margin-bottom: 20px;
    text-align: center;
  }

  h3 {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    line-height: 1.222;
    margin: 0 0 20px;
  }
`;
