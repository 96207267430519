module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PD7ZF77","includeInDevelopment":true,"gtmAuth":"LjRKUCpw6063kKVJYm4JPw","gtmPreview":"env-3","routeChangeEventName":"route_change","ignoredPages":["/cart/"],"defaultDataLayer":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
