import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { MetadataProvider as Provider } from '../Metadata.context';

export const MetadataProvider: React.FC = (props) => {
  const metadataResponse = useStaticQuery(graphql`
    query LadderMetadata {
      allSanityMetadata(filter: { page: { eq: "ladder.sport" } }) {
        nodes {
          page
          metadata {
            key
            value
          }
        }
      }
    }
  `);

  return <Provider value={metadataResponse} {...props} />;
};
