import React, { FunctionComponent, MouseEventHandler, useContext } from 'react';
import errorImage from 'svg/error.svg';
import image404 from 'svg/404.svg';
import image500 from 'svg/500.svg';
import {
  StyledContainer,
  ErrorContainer,
  StyledInnerWrap,
  SmallImageContainer,
  ErrorImage,
  ErrorImageContainer,
  ErrorTitle,
  ErrorMessage,
  ErrorButton,
  SecondaryErrorButton,
} from './Error.styles';
import {
  StandardFooter,
  StandardHeader,
} from 'components/HeaderFooter/HeaderFooter.styles';
import Helmet from 'react-helmet';
import { ThemeContext } from 'styled-components';

export const ErrorBoundaryView: React.FC<{
  error: Error;
  info: React.ErrorInfo;
  clearError: () => void;
}> = () => (
  <>
    <Helmet>
      <script src={process.env.GATSBY_HEADER_URL} defer></script>
    </Helmet>
    <StandardHeader hideSubscribeLink={true} />
    <Error />
    <StandardFooter />
  </>
);

export const Error: FunctionComponent<{
  title?: string;
  code?: string;
  errorMessage?: string;
  errorButtonText?: string;
  secondaryButtonText?: string;
  secondaryButtonHref?: string;
  onRetry?: () => void;
  onSecondaryClick?: MouseEventHandler;
  fullWidth?: boolean;
}> = ({
  title,
  code,
  errorMessage,
  onRetry = () => {
    window.location.reload();
  },
  errorButtonText,
  secondaryButtonText,
  secondaryButtonHref,
  onSecondaryClick,
  fullWidth,
}) => {
  const theme = useContext(ThemeContext);
  return (
    <StyledContainer fullWidth={!!fullWidth}>
      <ErrorContainer>
        <StyledInnerWrap>
          {!code && (
            <SmallImageContainer>
              <ErrorImage src={errorImage.url} alt="error" />
            </SmallImageContainer>
          )}
          {code === '404' && (
            <ErrorImageContainer>
              <ErrorImage src={image404.url} alt="404" />
            </ErrorImageContainer>
          )}
          {code === '500' && (
            <ErrorImageContainer>
              <ErrorImage src={image500.url} alt="500" />
            </ErrorImageContainer>
          )}
        </StyledInnerWrap>

        <ErrorTitle style={code ? { color: theme.colors.red800 } : {}}>
          {title || 'Oops! Something went wrong.'}
        </ErrorTitle>

        <ErrorMessage>
          {errorMessage || 'Please go back and try again.'}
        </ErrorMessage>
      </ErrorContainer>

      <StyledInnerWrap>
        <ErrorButton onClick={onRetry}>
          {errorButtonText || 'Retry'}
        </ErrorButton>

        {secondaryButtonText && (
          <SecondaryErrorButton
            onClick={onSecondaryClick}
            href={secondaryButtonHref}
          >
            {secondaryButtonText}
          </SecondaryErrorButton>
        )}
      </StyledInnerWrap>
    </StyledContainer>
  );
};
