import React, { useEffect } from 'react';
import styled, { css, createGlobalStyle } from 'styled-components';

const HEADER_DATA_COMPONENT = 'Header';
const FOOTER_DATA_COMPONENT = 'Footer';

interface GlobalComponentProps {
  name: string;
  className?: string;
  hideSubscribeLink?: boolean;
}

const GlobalComponent: React.FC<GlobalComponentProps> = ({
  name,
  className,
  ...rest
}) => {
  useEffect(() => {
    if (window.renderGlobalComponent) {
      window.renderGlobalComponent(name);
    }
  }, []);

  return <div data-component={name} className={className} {...rest} />;
};

export const StandardHeader = styled(GlobalComponent).attrs(() => ({
  name: HEADER_DATA_COMPONENT,
}))`
  min-height: 60px;

  @media (min-width: 980px) {
    min-height: 70px;
  }

  ${({ hideSubscribeLink }) =>
    hideSubscribeLink &&
    css`
      [data-test-id='login-link'] {
        margin-right: -20px;
      }

      [data-test-id='subscribe-link'] {
        display: none;
      }
    `};
`;

export const StandardFooter = styled(GlobalComponent).attrs(() => ({
  name: FOOTER_DATA_COMPONENT,
}))`
  footer {
    margin: 0 auto;
    padding-bottom: 60px;

    & > * {
      padding-left: 0;
      padding-right: 0;
    }
  }
  margin-bottom: 5rem;
`;

export const SellPageFooter = styled(StandardFooter)`
  footer {
    max-width: 980px;
  }
  footer > div:not(:first-child) {
    display: none;
  }
`;

export const LadderHeader = styled(GlobalComponent).attrs(() => ({
  name: 'Header',
}))`
  min-height: 50px;
  background: #292929;
`;

export const LadderFooter = styled(GlobalComponent).attrs(() => ({
  name: 'Footer',
}))``;

export const HideHeader = createGlobalStyle`
  div[data-component="${HEADER_DATA_COMPONENT}"] {
    display: none;
  }
`;
