import styled from 'styled-components';
import playButton from 'svg/play-button.svg';
import { ButtonProps } from '../Button/model';

export const ModalContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledVideoPlayButton = styled.a<ButtonProps>`
  height: 60px;
  width: 55px;
  background-image: url(${playButton.url});
  background-repeat: no-repeat;
  border: none;
  background-color: transparent;
  display: flex;
  flex: 1;
  text-align: center;
  text-decoration: none;
  outline: none;
  cursor: ${(props) => props.showPointer && 'pointer'};
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  appearance: none;
`;

export const PlayButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
