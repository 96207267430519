import {
  LadderHeader,
  StandardHeader,
} from 'components/HeaderFooter/HeaderFooter.styles';
import { isLadder } from 'helpers/Ladder';
import React from 'react';
import { PageType } from 'types';
import { isLoggedIn } from 'helpers/Customer';

export type HeaderVisibility = 'show' | 'hide' | 'loggedIn' | 'loggedOut';

export const Header: React.FC<{
  pageType: PageType;
  headerVisibility?: HeaderVisibility;
}> = ({ pageType, headerVisibility }) => {
  if (pageType === 'cart') {
    return null;
  }

  if (pageType === 'home' && !isLoggedIn() && !isLadder()) {
    return null;
  }

  if (isLadder()) {
    return <LadderHeader data-prop-ladder="" />;
  }

  if (headerVisibility) {
    switch (headerVisibility) {
      case 'hide':
        return null;
      case 'loggedIn':
        if (!isLoggedIn()) {
          return null;
        }
        break;
      case 'loggedOut':
        if (isLoggedIn()) {
          return null;
        }
        break;
    }
  }

  return <StandardHeader data-prop-pages="" />;
};
