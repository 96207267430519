/** @type {import('gatsby').GatsbyBrowser["onRouteUpdate"]} */
exports.onRouteUpdate = ({ location: { pathname } }, pluginOptions) => {
  pluginOptions.ignoredPages = pluginOptions.ignoredPages || [];

  if (pluginOptions.ignoredPages.includes(pathname)) {
    return;
  }

  if (
    process.env.NODE_ENV === `production` ||
    pluginOptions.includeInDevelopment
  ) {
    // wrap inside a timeout to ensure the title has properly been changed
    setTimeout(() => {
      const data = pluginOptions.dataLayerName
        ? window[pluginOptions.dataLayerName]
        : window.dataLayer;
      const eventName = pluginOptions.routeChangeEventName
        ? pluginOptions.routeChangeEventName
        : `gatsby-route-change`;

      data.push({ event: eventName });
    }, 50);
  }
};
