import React, { Fragment } from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { ServerError } from '@bbnb/openfit-frontend-shared';
import { getToken } from './Customer';

const isBrowser = typeof window !== 'undefined';
const enableBugsnag = isBrowser && process.env.GATSBY_BUGSNAG_ENABLE === 'true';

export function setBugsnagUser() {
  const token = getToken();
  if (token) {
    Bugsnag.setUser(token.sub, token.email, token.name);
  }
}

export function getErrorBoundary() {
  if (!isBrowser) {
    return Fragment;
  }
  let reactPlugin;

  console.log(
    'DEBUG BUGSNAG',
    enableBugsnag,
    isBrowser,
    process.env.GATSBY_BUGSNAG_ENABLE,
    process.env.GATSBY_BUGSNAG_ENABLE === 'true'
  );
  if (enableBugsnag) {
    Bugsnag.start({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      apiKey: process.env.GATSBY_BUGSNAG_API_KEY!,
      releaseStage: process.env.GATSBY_BUGSNAG_STAGE,
      appVersion: process.env.COMMIT_REF,
      plugins: [new BugsnagPluginReact()],
      onError: (e) => {
        console.error('ErrorBoundary Error', e.originalError);

        if (e.originalError instanceof ServerError) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          e.addMetadata('data', e.originalError.body as any);
        }

        // Silence random webkit errors.
        if (
          e.errors.some((error) => error.errorMessage.includes('window.webkit'))
        ) {
          return false;
        }

        return enableBugsnag;
      },
    });

    setBugsnagUser();
    reactPlugin = Bugsnag.getPlugin('react');
  }
  return reactPlugin ? reactPlugin.createErrorBoundary(React) : Fragment;
}

export function logError(error: string | Error, data?: Record<string, any>) {
  if (typeof error === 'string') {
    error = new Error(error);
  }
  console.error({ error, data });
  if (enableBugsnag) {
    Bugsnag.notify(error, (e) => {
      data && e.addMetadata('data', data);
    });
  }
}
