import { useMemo } from 'react';
import { useUserLocale } from '@bbnb/openfit-frontend-shared';
import {
  createClient,
  fetchExchange,
  cacheExchange,
  dedupExchange,
} from 'urql';
import { requestPolicyExchange } from '@urql/exchange-request-policy';
// import { persistedFetchExchange } from '@urql/exchange-persisted-fetch';
import { retryExchange } from '@urql/exchange-retry';

export const useGraphqlClient = () => {
  const [locale] = useUserLocale();
  const client = useMemo(
    () => getGraphqlClient({ 'accept-language': locale }),
    [locale]
  );
  return client;
};

export const getGraphqlClient = (headers: Record<string, string> = {}) => {
  const exchanges = [
    dedupExchange,
    requestPolicyExchange({
      ttl: 600000, // 10 minutes
    }),
    retryExchange({
      initialDelayMs: 1000,
      maxNumberAttempts: 1,
      retryIf: (error) => (error.networkError ? true : false),
    }),
    cacheExchange,
  ];

  let preview = false;
  if (typeof window !== 'undefined' && window.location.search) {
    const queryString = new URLSearchParams(window.location.search);
    preview = queryString.get('preview') === 'true';
  }

  if (!preview) {
    // Commenting this out until Netlify supports using query strings as part of the cache for functions.
    // exchanges.push(
    //   persistedFetchExchange({
    //     preferGetForPersistedQueries: true,
    //   })
    // );
  } else {
    headers['x-cms-preview'] = 'true';
  }
  exchanges.push(fetchExchange);

  return createClient({
    url: process.env.GATSBY_PRODUCT_GRAPHQL_URL || '',
    exchanges,
    fetchOptions: () => {
      return {
        headers,
      };
    },
  });
};
