import React, { useMemo } from 'react';
import {
  GatsbyImage as GatsbyPluginImage,
  GatsbyImageProps,
  IGatsbyImageData,
} from 'gatsby-plugin-image';
import { useImageLoading } from './ImageLoading.context';

export const GatsbyImage: React.FC<
  Omit<GatsbyImageProps, 'image'> & {
    image?: IGatsbyImageData;
  }
> = (props) => {
  const { image, ...restProps } = props;
  const contextLoading = useImageLoading();

  const imageMemoized = useMemo(() => {
    if (!image) return null;
    const imageMemoized = { ...image };
    // Gatsby-image-plugin doesn't support SVG. This is a workaround.
    // See https://github.com/gatsbyjs/gatsby/issues/30906#issuecomment-822270808
    if (
      imageMemoized.images &&
      !imageMemoized.images.fallback &&
      imageMemoized.images.sources?.[0].type === 'image/svg'
    ) {
      const source = imageMemoized.images.sources[0];
      const url = source.srcSet.substr(0, source.srcSet.indexOf('.svg') + 4);
      imageMemoized.images.fallback = {
        src: url,
        srcSet: source.srcSet,
        sizes: source.sizes,
      };
      imageMemoized.images.sources = undefined;
    }

    return imageMemoized;
  }, [image?.images.fallback?.src, image?.width, image?.height]);

  if (!imageMemoized) {
    console.error('[gatsby-plugin-image] - No image loaded', props);
    return null;
  }

  return (
    <GatsbyPluginImage
      loading={contextLoading.loading}
      {...restProps}
      image={imageMemoized}
    />
  );
};
