import { useLocation, WindowLocation } from '@reach/router';
import { SanityOpenfitCollectionPage } from 'graphql-types';
import { isRelativeUrl } from 'helpers/General';
import { logError } from 'helpers/Log';

function getUrlParam(paramName: string): string {
  let param = '';

  if (typeof window !== 'undefined') {
    param = new URLSearchParams(window.location.search).get(
      paramName
    ) as string;
  }

  return param;
}

function processQuery(params: URLSearchParams): URLSearchParams {
  const coupon = getUrlParam('coupon');
  if (coupon) {
    params.set('coupon', coupon);
  }

  const partner_code = getUrlParam('partner_code');
  if (partner_code) {
    params.set('partner_code', partner_code);
  }

  return params;
}

type CheckoutUrlObject = { product: string | string[]; [x: string]: any };

export function createCheckoutUrl(params: CheckoutUrlObject): string {
  const { product, ...rest } = params || {};

  const urlSearchParams = new URLSearchParams({ ...rest });

  if (Array.isArray(product)) {
    product.forEach((sku) => urlSearchParams.append('product', sku));
  } else {
    urlSearchParams.append('product', product);
  }

  const queryParams = processQuery(urlSearchParams);
  return `${process.env.GATSBY_HOME_URL}/checkout/?${queryParams}`;
}

export function getLoginURL(params: Record<string, string> = {}): string {
  const queryParams = processQuery(new URLSearchParams({ ...params }));
  return `${process.env.GATSBY_HOME_URL}/login?${queryParams}`;
}

export function processURL(url: string): string {
  if (url.startsWith('#')) return url;
  const urlObject = new URL(url);
  const queryParams = processQuery(new URLSearchParams(urlObject.search));
  return `${urlObject.pathname}?${queryParams}`;
}

const queryParamsToForward = [
  'icid',
  'utm_term',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'partner_code',
  'coupon',
];

export function processFullURL(url: string): string {
  if (!url) return '';
  if (typeof window === 'undefined') return url;
  try {
    if (url[0] === '#') {
      // Hashes shouldn't be fully qualified if they are relative, otherwise, scroll behaves oddly.
      return url;
    }

    // Safari doesn't support an explicit base of undefined which is why the ternary exists.
    const urlObject = isRelativeUrl(url)
      ? new URL(url, window.location.href)
      : new URL(url);
    const searchParams = new URLSearchParams(window.location.search);
    queryParamsToForward.forEach((param) => {
      const queryParamValue = searchParams.get(param);
      if (queryParamValue) {
        urlObject.searchParams.set(param, queryParamValue);
      }
    });

    return urlObject.toString();
  } catch (e) {
    logError(e);
    return url;
  }
}

export function queryParamsSatisfy(
  queryParams1: string,
  queryParams2: string
): boolean {
  let satisfy = true;
  const searchParams = new URLSearchParams(queryParams1);
  const displayForSearchParams = new URLSearchParams(queryParams2);
  for (const [name, value] of displayForSearchParams) {
    /* including "*" sign in the query param value triggers RegExp matching */
    /* e.g. utm_campaign=foo* matches utm_campaign=foobar, utm_campaign=food etc. */
    const hasWildCard = value?.includes('*');
    if (
      hasWildCard &&
      !new RegExp(`^${value.replace(/\*/g, '.*')}$`).test(
        searchParams.get(name) || ''
      )
    ) {
      satisfy = false;
    } else if (!hasWildCard && searchParams.get(name) !== value) {
      satisfy = false;
    }
  }

  return satisfy;
}

export function queryParamsSatisfyAny(
  queryParams: string,
  queryParamsList: string[]
): boolean {
  for (const queryParam of queryParamsList) {
    if (queryParamsSatisfy(queryParams, queryParam)) {
      return true;
    }
  }
  return false;
}

export function useCollection(
  defaultCollection?: Pick<SanityOpenfitCollectionPage, 'slug' | 'name'>,
  defaultCollectionAnchor?: string
): {
  collectionTitle: string;
  collectionUrl: string;
} {
  const location = useLocation() as WindowLocation<{
    collectionTitle?: string;
    collectionUrl?: string;
  }>;

  const collectionTitle =
    location.state?.collectionTitle || defaultCollection?.name || 'Openfit';
  const defaultCollectionUrl = defaultCollection?.slug?.current
    ? `${defaultCollection?.slug?.current}/`
    : '';
  let collectionUrl =
    location.state?.collectionUrl || `/collections/${defaultCollectionUrl}`;

  if (defaultCollectionAnchor) {
    collectionUrl += `#${defaultCollectionAnchor}`.toLowerCase();
  }

  return {
    collectionTitle,
    collectionUrl,
  };
}
