import React from 'react';
import {
  SellPageFooter,
  StandardFooter,
  LadderFooter,
} from 'components/HeaderFooter/HeaderFooter.styles';
import { isLadder } from 'helpers/Ladder';
import { PageType } from 'types';

export const Footer: React.FC<{ pageType: PageType }> = ({ pageType }) => {
  if (pageType === 'cart') {
    return null;
  }

  if (isLadder()) {
    return <LadderFooter data-prop-ladder="" />;
  }

  if (pageType === 'sell') {
    return <SellPageFooter data-prop-pages="" />;
  }

  return <StandardFooter data-prop-pages="" />;
};
