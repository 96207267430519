// eslint-disable-next-line no-use-before-define
import React from 'react';
import { getErrorBoundary } from 'helpers/Log';
import { ErrorBoundaryView } from 'components/Error';
import { App } from 'components/App';
import { ThemeProvider } from 'styled-components';
import theme, { ladderTheme } from 'theme';
// import { enableFullPageTestSupport } from 'helpers/FullPageTest';

const isLadder = process.env.GATSBY_SITE === 'ladder';

const ErrorBoundary = getErrorBoundary();

export const onClientEntry = () => {
  if (typeof window.IntersectionObserver === 'undefined') {
    import('intersection-observer');
  }

  /* Full Page A/B Test Support disabled for now
    until we decide it's ready for deploy
    so we are not blocking the releases of pages

  // Full Page A/B Test Support is enabled only for Openfit site right now
  // Enabling on Ladder should include update in the sanity studio to expose it in Ladder section
  // and hooking up the lambda@edge functions in ladder cloudfront
  // In terms of sanity schema for ladder that can be shared with openfit with special filter for site
  // or different document name - for openfit it's "fullpage-abtest"
  if (isLadder) {
    return undefined;
  }

  return enableFullPageTestSupport();

  */
};

export const shouldUpdateScroll = ({ routerProps }) => {
  const hash = routerProps.location.hash;
  if (hash) {
    const node = document.getElementById(hash.substring(1));
    if (node) {
      window.scrollTo(0, node.offsetTop);
      return false;
    }
  }

  // Gatsby tries to remember your scroll position and restore it and this breaks
  // sticky CTA, breaks attempting to navigate to fragment identifiers, and breaks
  // scroll to top behavior when navigating between pages.
  // return [0, 0] makes this work correctly.
  // return [0, 0];
  return [0, 0];
};

export const wrapRootElement = ({ element }) => (
  <ErrorBoundary FallbackComponent={ErrorBoundaryView}>
    <ThemeProvider theme={isLadder ? ladderTheme : theme}>
      {element}
    </ThemeProvider>
  </ErrorBoundary>
);

export const wrapPageElement = ({ element, props }) => {
  return <App {...props}>{element}</App>;
};
