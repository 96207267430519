import React from 'react';
import { createContext, useContext } from 'react';

export interface ImageLoadingProviderProps {
  loading: 'lazy' | 'eager';
}

export const ImageLoadingContext = createContext<ImageLoadingProviderProps>(
  {} as ImageLoadingProviderProps
);

export const ImageLoadingProvider: React.FC<{
  value: ImageLoadingProviderProps;
}> = ({ children, value }) => {
  return (
    <ImageLoadingContext.Provider value={{ loading: value.loading }}>
      {children}
    </ImageLoadingContext.Provider>
  );
};

export const useImageLoading = () => useContext(ImageLoadingContext);

export const ImageLoadingWrapper: React.FC<{ eager?: boolean }> = ({
  eager,
  children,
}) => {
  if (eager) {
    return (
      <ImageLoadingProvider value={{ loading: 'eager' }} children={children} />
    );
  }

  return <React.Fragment children={children} />;
};
