import { UserMeData } from '@bbnb/openfit-frontend-shared';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { logError } from './Log';

export const getToken = (): IdToken | null => {
  let response = null;
  try {
    response = jwtDecode<IdToken>(Cookies.get('id_token') as string);
  } catch (e) {}

  return response;
};

export function getAuthorized() {
  return Cookies.get('of-authorization') || '';
}

export const isEntitled = (userMeData: UserMeData): boolean => {
  return !userMeData.upgrade;
};

export const isLoggedIn = (): boolean => !!getAuthorized();

export async function logout() {
  try {
    if (typeof window !== 'undefined' && window.COMMON_HEADER?.logout) {
      window.COMMON_HEADER?.logout();
      window.COMMON_HEADER?.refresh();
    }
  } catch (e) {
    logError(e, { message: 'Cannot logout' });
  }
}

export function isValidForUserType(
  isEntitled: boolean,
  userType: 'non-entitled' | 'entitled' | ''
) {
  if (!userType) {
    return true;
  }
  return (
    (isEntitled && userType === 'entitled') ||
    (!isEntitled && userType === 'non-entitled')
  );
}
