import React from 'react';

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'transparentBorderBasic400'
  | 'primaryBorder'
  | 'primaryUnderline'
  | 'transparent'
  | 'disabled'
  | 'secondaryInverted';

export interface TinyButtonProps extends React.HTMLProps<HTMLAnchorElement> {
  onClick?(props: any): void;
  variant?: ButtonVariant;
}

export interface ButtonProps {
  onClick?(props: any): void;
  variant?: ButtonVariant;
  dropShadow?: boolean;
  showPointer?: boolean;
  fullWidthMobile?: boolean;
  width?: string[];
  fontSize?: string[];
}

export enum Button2Variant {
  MarketingRed = 'MarketingRed',
  PrimaryBlue = 'PrimaryBlue',
  PrimaryRed = 'PrimaryRed',
  SecondaryBlue = 'SecondaryBlue',
  SecondaryRed = 'SecondaryRed',
}

export interface Button2Props {
  onClick?(props: any): void;
  variant?: Button2Variant;
}
