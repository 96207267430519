import React from 'react';
import { StyledComponent } from 'styled-components';
import { useIsBrowser } from 'utils/general';
import { processFullURL } from 'utils/url';
import { StyledVideoPlayButton } from '../YoutubeModal/YoutubeModal.styles';
import { StyledButton, StyledTinyButton, StyledButton2 } from './styles';

const withWrapper = (ChildComponent: StyledComponent<'a', any>) => {
  return (props: Record<any, any>) => {
    const isBrowser = useIsBrowser();
    return (
      <ChildComponent
        {...props}
        href={processFullURL(props.href)}
        key={isBrowser.toString()}
      />
    );
  };
};

export const Button = withWrapper(StyledButton);
export const TinyButton = withWrapper(StyledTinyButton);
export const Button2 = withWrapper(StyledButton2);
export const VideoPlayButton = withWrapper(StyledVideoPlayButton);
