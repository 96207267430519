import React, { createContext, useContext } from 'react';
import { useCouponDetails, useGclid } from '@bbnb/openfit-frontend-shared';
import { CouponDetails } from '@bbnb/openfit-frontend-shared';
import { ImageLoadingProvider } from 'components/Common/GatsbyImage';

export interface AppContextProps {
  coupon: CouponDetails;
}

export const AppContext = createContext<AppContextProps>({} as AppContextProps);

export const useApp = () => useContext(AppContext);

export const AppProvider: React.FunctionComponent = (props) => {
  useGclid();
  const coupon = useCouponDetails();

  return (
    <ImageLoadingProvider value={{ loading: 'lazy' }}>
      <AppContext.Provider value={{ coupon }} {...props} />
    </ImageLoadingProvider>
  );
};
