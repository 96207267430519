import React from 'react';
import useScript from 'react-script-hook';

const onload = () => {
  if (window.utag && window.utag.cfg) {
    window.utag.cfg.noview = true;
  }
};

export const TealiumLoader: React.FC = () => {
  useScript({
    src: `//tags.tiqcdn.com/utag/${process.env.GATSBY_TEALIUM_ACCOUNT}/${process.env.GATSBY_TEALIUM_PROFILE}/${process.env.GATSBY_TEALIUM_ENV}/utag.js`,
    checkForExisting: true,
    onload,
  });
  return null;
};
