import * as React from 'react';
import { Helmet, HelmetProps } from 'react-helmet';
import { Provider as URQLProvider } from 'urql';
import { useGraphqlClient } from 'helpers/urql';
import { MetadataProvider } from 'Context/Metadata/Components/MetadataProvider';
import { setMetadata } from '@bbnb/openfit-frontend-shared';

import Styles from './styles';
import { TealiumLoader } from 'components/TealiumLoader';
import { PageProps } from 'gatsby';
import { BlockType, PageType } from 'types';
import {
  Header,
  Footer,
  HideHeader,
  HeaderVisibility,
} from 'components/HeaderFooter';
import { AppProvider } from './AppContext';
import useScript from 'react-script-hook';
import { isLadder } from 'helpers/Ladder';

const preconnectLinkProps: HelmetProps['link'] = [];

const addPreconnectDomain = (domain: string) => {
  const href = `//${domain}`;
  preconnectLinkProps.push({
    rel: 'preconnect',
    href,
    crossOrigin: 'anonymous',
  });
  preconnectLinkProps.push({
    rel: 'dns-prefetch',
    href,
  });
};

type PageData = {
  page?: {
    optimizelyType?: string;
    blocks?: BlockType[];
    hideHeader?: boolean;
    headerVisibility?: HeaderVisibility;
  };
};

export const App: React.FunctionComponent<PageProps<PageData>> = (props) => {
  const isOptimizelyEnabled = process.env.GATSBY_OPTIMIZELY_ENABLED === 'true';

  const optimizelyType = props.data?.page?.optimizelyType || '';
  const containsOptimizelyExperiment = props.data?.page?.blocks?.find(
    (block: BlockType) => block?.__typename === 'SanityOptimizelyExperiment'
  );
  const optimizelySrc = process.env.GATSBY_OPTIMIZELY_SRC;
  let optimizelyScript = null;
  const lazyOptimizely =
    optimizelyType === 'lazy' || containsOptimizelyExperiment;
  const blockingOptimizely = optimizelyType === 'blocking' || isLadder();

  let pageType: PageType = '';
  if (props.location.pathname.startsWith('/plans/')) {
    pageType = 'sell';
  } else if (props.location.pathname.startsWith('/cart/')) {
    pageType = 'cart';
  } else if (props.location.pathname === '/') {
    pageType = 'home';
  }

  if (isOptimizelyEnabled && (lazyOptimizely || blockingOptimizely)) {
    addPreconnectDomain('logx.optimizely.com');
    optimizelyScript = (
      <script
        src={optimizelySrc}
        {...(lazyOptimizely && { defer: true })}
      ></script>
    );
  }

  // This can't be in Helmet, as it will process the header script too early.
  // Gatsby puts the framework/app bundles in the post body rather than the head.

  useScript({
    src: pageType !== 'cart' ? process.env.GATSBY_HEADER_URL : null,
    checkForExisting: true,
  });

  setMetadata('app', 'pages');

  const graphqlClient = useGraphqlClient();

  return (
    <>
      <MetadataProvider>
        <Helmet link={preconnectLinkProps}>
          {isOptimizelyEnabled && blockingOptimizely ? (
            <link rel="preload" href={optimizelySrc} as="script"></link>
          ) : null}
          {optimizelyScript}
        </Helmet>
        {props?.data?.page?.hideHeader && <HideHeader />}
        {pageType !== 'cart' && <TealiumLoader />}
        <Styles />
        <Header
          pageType={pageType}
          headerVisibility={props.data?.page?.headerVisibility}
        />
        <URQLProvider value={graphqlClient}>
          <AppProvider>{props.children}</AppProvider>
        </URQLProvider>
        <Footer pageType={pageType} />
      </MetadataProvider>
    </>
  );
};

export default App;
