/* this file is a styled-system wrapper with injected theme */

import theme from 'theme';
import * as styledSystem from 'styled-system';

export type TextColorProps = {
  textColor?: ColorProps['color'];
};

const textColorFn = styledSystem.system({
  textColor: {
    property: 'color',
    scale: 'colors',
  },
});

export const textColor = (options: TextColorProps) =>
  textColorFn({ theme, ...options });

const width = (options: styledSystem.WidthProps) =>
  styledSystem.width({ theme, ...options });

type WidthProps = styledSystem.WidthProps;
export { width, WidthProps };

const height = (options: styledSystem.HeightProps) =>
  styledSystem.height({ theme, ...options });

type HeightProps = styledSystem.HeightProps;
export { height, HeightProps };

const minWidth = (options: styledSystem.MinWidthProps) =>
  styledSystem.minWidth({ theme, ...options });

type MinWidthProps = styledSystem.MinWidthProps;
export { minWidth, MinWidthProps };

const minHeight = (options: styledSystem.MinHeightProps) =>
  styledSystem.minHeight({ theme, ...options });

type MinHeightProps = styledSystem.MinHeightProps;
export { minHeight, MinHeightProps };

const maxWidth = (options: styledSystem.MaxWidthProps) =>
  styledSystem.maxWidth({ theme, ...options });

type MaxWidthProps = styledSystem.MaxWidthProps;
export { maxWidth, MaxWidthProps };

const maxHeight = (options: styledSystem.MaxHeightProps) =>
  styledSystem.maxHeight({ theme, ...options });

type MaxHeightProps = styledSystem.MaxHeightProps;
export { maxHeight, MaxHeightProps };

const size = (options: styledSystem.SizeProps) =>
  styledSystem.size({ theme, ...options });

type SizeProps = styledSystem.SizeProps;
export { size, SizeProps };

const verticalAlign = (options: styledSystem.VerticalAlignProps) =>
  styledSystem.verticalAlign({ theme, ...options });

type VerticalAlignProps = styledSystem.VerticalAlignProps;
export { verticalAlign, VerticalAlignProps };

const display = (options: styledSystem.DisplayProps) =>
  styledSystem.display({ theme, ...options });

type DisplayProps = styledSystem.DisplayProps;
export { display, DisplayProps };

const overflow = (options: styledSystem.OverflowProps) =>
  styledSystem.overflow({ theme, ...options });

type OverflowProps = styledSystem.OverflowProps;
export { overflow, OverflowProps };

const opacity = (options: styledSystem.OpacityProps) =>
  styledSystem.opacity({ theme, ...options });

type OpacityProps = styledSystem.OpacityProps;
export { opacity, OpacityProps };

const fontSize = (options: styledSystem.FontSizeProps) =>
  styledSystem.fontSize({ theme, ...options });

type FontSizeProps = styledSystem.FontSizeProps;
export { fontSize, FontSizeProps };

const fontFamily = (options: styledSystem.FontFamilyProps) =>
  styledSystem.fontFamily({ theme, ...options });

type FontFamilyProps = styledSystem.FontFamilyProps;
export { fontFamily, FontFamilyProps };

const fontWeight = (options: styledSystem.FontWeightProps) =>
  styledSystem.fontWeight({ theme, ...options });

type FontWeightProps = styledSystem.FontWeightProps;
export { fontWeight, FontWeightProps };

const lineHeight = (options: styledSystem.LineHeightProps) =>
  styledSystem.lineHeight({ theme, ...options });

type LineHeightProps = styledSystem.LineHeightProps;
export { lineHeight, LineHeightProps };

const textAlign = (options: styledSystem.TextAlignProps) =>
  styledSystem.textAlign({ theme, ...options });

type TextAlignProps = styledSystem.TextAlignProps;
export { textAlign, TextAlignProps };

const fontStyle = (options: styledSystem.FontStyleProps) =>
  styledSystem.fontStyle({ theme, ...options });

type FontStyleProps = styledSystem.FontStyleProps;
export { fontStyle, FontStyleProps };

const letterSpacing = (options: styledSystem.LetterSpacingProps) =>
  styledSystem.letterSpacing({ theme, ...options });

type LetterSpacingProps = styledSystem.LetterSpacingProps;
export { letterSpacing, LetterSpacingProps };

const alignItems = (options: styledSystem.AlignItemsProps) =>
  styledSystem.alignItems({ theme, ...options });

type AlignItemsProps = styledSystem.AlignItemsProps;
export { alignItems, AlignItemsProps };

const alignContent = (options: styledSystem.AlignContentProps) =>
  styledSystem.alignContent({ theme, ...options });

type AlignContentProps = styledSystem.AlignContentProps;
export { alignContent, AlignContentProps };

const justifyItems = (options: styledSystem.JustifyItemsProps) =>
  styledSystem.justifyItems({ theme, ...options });

type JustifyItemsProps = styledSystem.JustifyItemsProps;
export { justifyItems, JustifyItemsProps };

const justifyContent = (options: styledSystem.JustifyContentProps) =>
  styledSystem.justifyContent({ theme, ...options });

type JustifyContentProps = styledSystem.JustifyContentProps;
export { justifyContent, JustifyContentProps };

const flexWrap = (options: styledSystem.FlexWrapProps) =>
  styledSystem.flexWrap({ theme, ...options });

type FlexWrapProps = styledSystem.FlexWrapProps;
export { flexWrap, FlexWrapProps };

const flexDirection = (options: styledSystem.FlexDirectionProps) =>
  styledSystem.flexDirection({ theme, ...options });

type FlexDirectionProps = styledSystem.FlexDirectionProps;
export { flexDirection, FlexDirectionProps };

const flex = (options: styledSystem.FlexProps) =>
  styledSystem.flex({ theme, ...options });

type FlexProps = styledSystem.FlexProps;
export { flex, FlexProps };

const flexGrow = (options: styledSystem.FlexGrowProps) =>
  styledSystem.flexGrow({ theme, ...options });

type FlexGrowProps = styledSystem.FlexGrowProps;
export { flexGrow, FlexGrowProps };

const flexShrink = (options: styledSystem.FlexShrinkProps) =>
  styledSystem.flexShrink({ theme, ...options });

type FlexShrinkProps = styledSystem.FlexShrinkProps;
export { flexShrink, FlexShrinkProps };

const flexBasis = (options: styledSystem.FlexBasisProps) =>
  styledSystem.flexBasis({ theme, ...options });

type FlexBasisProps = styledSystem.FlexBasisProps;
export { flexBasis, FlexBasisProps };

const justifySelf = (options: styledSystem.JustifySelfProps) =>
  styledSystem.justifySelf({ theme, ...options });

type JustifySelfProps = styledSystem.JustifySelfProps;
export { justifySelf, JustifySelfProps };

const alignSelf = (options: styledSystem.AlignSelfProps) =>
  styledSystem.alignSelf({ theme, ...options });

type AlignSelfProps = styledSystem.AlignSelfProps;
export { alignSelf, AlignSelfProps };

const order = (options: styledSystem.OrderProps) =>
  styledSystem.order({ theme, ...options });

type OrderProps = styledSystem.OrderProps;
export { order, OrderProps };

const gridGap = (options: styledSystem.GridGapProps) =>
  styledSystem.gridGap({ theme, ...options });

type GridGapProps = styledSystem.GridGapProps;
export { gridGap, GridGapProps };

const gridColumnGap = (options: styledSystem.GridColumnGapProps) =>
  styledSystem.gridColumnGap({ theme, ...options });

type GridColumnGapProps = styledSystem.GridColumnGapProps;
export { gridColumnGap, GridColumnGapProps };

const gridRowGap = (options: styledSystem.GridRowGapProps) =>
  styledSystem.gridRowGap({ theme, ...options });

type GridRowGapProps = styledSystem.GridRowGapProps;
export { gridRowGap, GridRowGapProps };

const gridColumn = (options: styledSystem.GridColumnProps) =>
  styledSystem.gridColumn({ theme, ...options });

type GridColumnProps = styledSystem.GridColumnProps;
export { gridColumn, GridColumnProps };

const gridRow = (options: styledSystem.GridRowProps) =>
  styledSystem.gridRow({ theme, ...options });

type GridRowProps = styledSystem.GridRowProps;
export { gridRow, GridRowProps };

const gridAutoFlow = (options: styledSystem.GridAutoFlowProps) =>
  styledSystem.gridAutoFlow({ theme, ...options });

type GridAutoFlowProps = styledSystem.GridAutoFlowProps;
export { gridAutoFlow, GridAutoFlowProps };

const gridAutoColumns = (options: styledSystem.GridAutoColumnsProps) =>
  styledSystem.gridAutoColumns({ theme, ...options });

type GridAutoColumnsProps = styledSystem.GridAutoColumnsProps;
export { gridAutoColumns, GridAutoColumnsProps };

const gridAutoRows = (options: styledSystem.GridAutoRowsProps) =>
  styledSystem.gridAutoRows({ theme, ...options });

type GridAutoRowsProps = styledSystem.GridAutoRowsProps;
export { gridAutoRows, GridAutoRowsProps };

const gridTemplateColumns = (options: styledSystem.GridTemplateColumnsProps) =>
  styledSystem.gridTemplateColumns({ theme, ...options });

type GridTemplateColumnsProps = styledSystem.GridTemplateColumnsProps;
export { gridTemplateColumns, GridTemplateColumnsProps };

const gridTemplateRows = (options: styledSystem.GridTemplateRowsProps) =>
  styledSystem.gridTemplateRows({ theme, ...options });

type GridTemplateRowsProps = styledSystem.GridTemplateRowsProps;
export { gridTemplateRows, GridTemplateRowsProps };

const gridTemplateAreas = (options: styledSystem.GridTemplateAreasProps) =>
  styledSystem.gridTemplateAreas({ theme, ...options });

type GridTemplateAreasProps = styledSystem.GridTemplateAreasProps;
export { gridTemplateAreas, GridTemplateAreasProps };

const gridArea = (options: styledSystem.GridAreaProps) =>
  styledSystem.gridArea({ theme, ...options });

type GridAreaProps = styledSystem.GridAreaProps;
export { gridArea, GridAreaProps };

const borderWidth = (options: styledSystem.BorderWidthProps) =>
  styledSystem.borderWidth({ theme, ...options });

type BorderWidthProps = styledSystem.BorderWidthProps;
export { borderWidth, BorderWidthProps };

const borderStyle = (options: styledSystem.BorderStyleProps) =>
  styledSystem.borderStyle({ theme, ...options });

type BorderStyleProps = styledSystem.BorderStyleProps;
export { borderStyle, BorderStyleProps };

const borderColor = (options: styledSystem.BorderColorProps) =>
  styledSystem.borderColor({ theme, ...options });

type BorderColorProps = styledSystem.BorderColorProps;
export { borderColor, BorderColorProps };

const borderTop = (options: styledSystem.BorderTopProps) =>
  styledSystem.borderTop({ theme, ...options });

type BorderTopProps = styledSystem.BorderTopProps;
export { borderTop, BorderTopProps };

const borderRight = (options: styledSystem.BorderRightProps) =>
  styledSystem.borderRight({ theme, ...options });

type BorderRightProps = styledSystem.BorderRightProps;
export { borderRight, BorderRightProps };

const borderBottom = (options: styledSystem.BorderBottomProps) =>
  styledSystem.borderBottom({ theme, ...options });

type BorderBottomProps = styledSystem.BorderBottomProps;
export { borderBottom, BorderBottomProps };

const borderLeft = (options: styledSystem.BorderLeftProps) =>
  styledSystem.borderLeft({ theme, ...options });

type BorderLeftProps = styledSystem.BorderLeftProps;
export { borderLeft, BorderLeftProps };

const borderRadius = (options: styledSystem.BorderRadiusProps) =>
  styledSystem.borderRadius({ theme, ...options });

type BorderRadiusProps = styledSystem.BorderRadiusProps;
export { borderRadius, BorderRadiusProps };

const backgroundImage = (options: styledSystem.BackgroundImageProps) =>
  styledSystem.backgroundImage({ theme, ...options });

type BackgroundImageProps = styledSystem.BackgroundImageProps;
export { backgroundImage, BackgroundImageProps };

const backgroundSize = (options: styledSystem.BackgroundSizeProps) =>
  styledSystem.backgroundSize({ theme, ...options });

type BackgroundSizeProps = styledSystem.BackgroundSizeProps;
export { backgroundSize, BackgroundSizeProps };

const backgroundPosition = (options: styledSystem.BackgroundPositionProps) =>
  styledSystem.backgroundPosition({ theme, ...options });

type BackgroundPositionProps = styledSystem.BackgroundPositionProps;
export { backgroundPosition, BackgroundPositionProps };

const backgroundRepeat = (options: styledSystem.BackgroundRepeatProps) =>
  styledSystem.backgroundRepeat({ theme, ...options });

type BackgroundRepeatProps = styledSystem.BackgroundRepeatProps;
export { backgroundRepeat, BackgroundRepeatProps };

const zIndex = (options: styledSystem.ZIndexProps) =>
  styledSystem.zIndex({ theme, ...options });

type ZIndexProps = styledSystem.ZIndexProps;
export { zIndex, ZIndexProps };

const top = (options: styledSystem.TopProps) =>
  styledSystem.top({ theme, ...options });

type TopProps = styledSystem.TopProps;
export { top, TopProps };

const right = (options: styledSystem.RightProps) =>
  styledSystem.right({ theme, ...options });

type RightProps = styledSystem.RightProps;
export { right, RightProps };

const bottom = (options: styledSystem.BottomProps) =>
  styledSystem.bottom({ theme, ...options });

type BottomProps = styledSystem.BottomProps;
export { bottom, BottomProps };

const left = (options: styledSystem.LeftProps) =>
  styledSystem.left({ theme, ...options });

type LeftProps = styledSystem.LeftProps;
export { left, LeftProps };

const margin = (options: styledSystem.MarginProps) =>
  styledSystem.margin({ theme, ...options });

type MarginProps = styledSystem.MarginProps;
export { margin, MarginProps };

const padding = (options: styledSystem.PaddingProps) =>
  styledSystem.padding({ theme, ...options });

type PaddingProps = styledSystem.PaddingProps;
export { padding, PaddingProps };

const space = (options: styledSystem.SpaceProps) =>
  styledSystem.space({ theme, ...options });

type SpaceProps = styledSystem.SpaceProps;
export { space, SpaceProps };

const color = (options: styledSystem.ColorProps) =>
  styledSystem.color({ theme, ...options });

type ColorProps = styledSystem.ColorProps;
export { color, ColorProps };

const layout = (options: styledSystem.LayoutProps) =>
  styledSystem.layout({ theme, ...options });

type LayoutProps = styledSystem.LayoutProps;
export { layout, LayoutProps };

const typography = (options: styledSystem.TypographyProps) =>
  styledSystem.typography({ theme, ...options });

type TypographyProps = styledSystem.TypographyProps;
export { typography, TypographyProps };

const flexbox = (options: styledSystem.FlexboxProps) =>
  styledSystem.flexbox({ theme, ...options });

type FlexboxProps = styledSystem.FlexboxProps;
export { flexbox, FlexboxProps };

const border = (options: styledSystem.BorderProps) =>
  styledSystem.border({ theme, ...options });

type BorderProps = styledSystem.BorderProps;
export { border, BorderProps };

const background = (options: styledSystem.BackgroundProps) =>
  styledSystem.background({ theme, ...options });

type BackgroundProps = styledSystem.BackgroundProps;
export { background, BackgroundProps };

const position = (options: styledSystem.PositionProps) =>
  styledSystem.position({ theme, ...options });

type PositionProps = styledSystem.PositionProps;
export { position, PositionProps };

const grid = (options: styledSystem.GridProps) =>
  styledSystem.grid({ theme, ...options });

type GridProps = styledSystem.GridProps;
export { grid, GridProps };

const shadow = (options: styledSystem.ShadowProps) =>
  styledSystem.shadow({ theme, ...options });

type ShadowProps = styledSystem.ShadowProps;
export { shadow, ShadowProps };

const boxShadow = (options: styledSystem.BoxShadowProps) =>
  styledSystem.boxShadow({ theme, ...options });

type BoxShadowProps = styledSystem.BoxShadowProps;
export { boxShadow, BoxShadowProps };

const textShadow = (options: styledSystem.TextShadowProps) =>
  styledSystem.textShadow({ theme, ...options });

type TextShadowProps = styledSystem.TextShadowProps;
export { textShadow, TextShadowProps };

const buttonStyle = (options: styledSystem.ButtonStyleProps) =>
  styledSystem.buttonStyle({ theme, ...options });

type ButtonStyleProps = styledSystem.ButtonStyleProps;
export { buttonStyle, ButtonStyleProps };

const textStyle = (options: styledSystem.TextStyleProps) =>
  styledSystem.textStyle({ theme, ...options });

type TextStyleProps = styledSystem.TextStyleProps;
export { textStyle, TextStyleProps };

const colorStyle = (options: styledSystem.ColorStyleProps) =>
  styledSystem.colorStyle({ theme, ...options });

type ColorStyleProps = styledSystem.ColorStyleProps;
export { colorStyle, ColorStyleProps };

const borders = (options: styledSystem.BordersProps) =>
  styledSystem.borders({ theme, ...options });

type BordersProps = styledSystem.BordersProps;
export { borders, BordersProps };

const variant = styledSystem.variant;
export { variant };

// Generated with:
//
// const notUsed = [
//   'overflowX',
//   'overflowY',
//   'get',
//   'style',
//   'compose',
//   'variant',
//   'createParser',
//   'createStyleFunction',
//   'system',
// ];
// const names = Object.keys(styledSystem).filter(k => !notUsed.includes(k));
// const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

// let code = '';
// names.forEach(name => {
//   code += `
//     const ${name} = (options: styledSystem.${capitalize(
//     name
//   )}Props) =>
//     styledSystem.${name}({ theme, ...options });

//     type ${capitalize(name)}Props = styledSystem.${capitalize(
//     name
//   )}Props;
//     export { ${name}, ${capitalize(name)}Props };
//   \n\n`;
// });

// console.log(code);
